<template>
  <!-- 关于我们-->
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic1">
        <div class="title">
          <div class="titleA">ABOUT</div>
          <div class="titleB">关于全购</div>
          <div class="titleC">六年品牌·服务保障·我们的用心·您的安心</div>
        </div>
        <div class="content">
          <div class="contentA">·公司简介</div>
          <div class="contentB">全购网络有限公司（简称全购网络），成立于2019年1月，主要从事企业管理SAAS软件研发与服务，同时在产品线上兼容各类小程序，APP应用的开发。研发团队本科以上占80%以上。国家高新技术企业，具有电信增值业务许可证（ICP经营），一项发明专利已受理并公示中，14项软件著作权证书。公司以创新为主导，研发投入资金占30%以上。公司具有稳定的研发机构与队伍，采用了合伙人制度，合伙人遍布上海，安徽，福建，东北，陕西等，保障了充足的研发力量，3年内，研发机构将达到10个。以构建人类和谐新文明，做人类历史上活的最久的企业为使命与愿景，全购将发展成为一家有传承、有担当，以“客户企业目标价值最大化”与“用户权益最大化”为公司价值观的科创型上市企业。</div>
        </div>
      </div>
      <div class="pic2">
         <div class="pic2_titleA">·公司荣誉</div>
         <div class="pic2_titleB">科创型企业 高新技术企业</div>
         <div class="img1">
           <img :src="img1">
         </div>
         <div class="pic2_titleB">合肥高新区2021年雏鹰企业</div>
         <div class="img1">
           <img :src="img921">
         </div>
         <div class="pic2_titleA">·公司资质</div>
         <div class="pic2_titleB">电信增值业务许可证</div>
         <div class="img2">
          <img :src="img2">
         </div>
         <div class="pic2_titleA">·知识产权</div>
         <div class="pic2_titleB">科创型企业 高新技术企业</div>
         <div class="img3s">
           <div class="img3" v-for="(img3,index) in img3s" :key="index">
             <img :src="img3.img">
           </div>
         </div>
      </div>
      <div class="pic3">
        <div class="pic3_titleA">·公司业绩</div>
        <div class="pic3_titleB">案例展示</div>
        <div class="pic3_border"></div>
        <div class="pic3_form">
          <div class="pic3_form_title">
            <div class="round"></div>
            <div class="round-title">01. 电商小程序</div>
          </div>
          <div class="pic3_form_imgs">
            <div class="img4">
              <img :src="img5">
            </div>
            <div class="img5">
              <img :src="img6">
            </div>
          </div>
          <div class="pic3_form_title">
            <div class="round"></div>
            <div class="round-title">02. 任务APP</div>
          </div>
          <div class="pic3_form_imgs">
            <div class="img4">
              <img :src="img7">
            </div>
            <div class="img5">
              <img :src="img8">
            </div>
          </div>
          <div class="pic3_form_title">
            <div class="round"></div>
            <div class="round-title">03. 社区APP</div>
          </div>
          <div class="pic3_form_imgs">
            <div class="img4">
              <img :src="img9">
            </div>
            <div class="img5">
              <img :src="img10">
            </div>
          </div>
          <div class="pic3_form_title">
            <div class="round"></div>
            <div class="round-title">04. 工会APP</div>
          </div>
          <div class="pic3_form_imgs">
            <div class="img4">
              <img :src="img11">
            </div>
            <div class="img5">
              <img :src="img12">
            </div>
          </div>
          <div class="pic3_form_title">
            <div class="round"></div>
            <div class="round-title">05. 教育培训APP</div>
          </div>
          <div class="pic3_form_imgs">
            <div class="img4">
              <img :src="img13">
            </div>
            <div class="img5">
              <img :src="img14">
            </div>
          </div>
          <div class="pic3_form_title">
            <div class="round"></div>
            <div class="round-title">06. 酒店管理系统</div>
          </div>
          <div class="pic3_form_imgs2">
            <img :src="img15">
          </div>
          <div class="pic3_form_title">
            <div class="round"></div>
            <div class="round-title">07. 劳务众包用工管理系统</div>
          </div>
          <div class="pic3_form_imgs3">
            <img :src="img16">
          </div>
        </div>
      </div>
      <div class="contact">
        <div class="contact_title">联系我们</div>
        <div class="contact_form">
          <div class="contact_content">全购网络有限公司</div>
          <div class="contact_content">地址：安徽省合肥市蜀山区国家大学科技园C102</div>
          <div class="contact_content">手机： 13817169848</div>
          <div class="contact_content">微信号： wwwi2b2b</div>
          <div class="contact_content">邮箱：kaifa@i2f2f.com</div>
          <div class="contact_content">工作时间：周一到周五（09:00am - 18:00pm）欢迎咨询我们！</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page5',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      img1: require('@/assets/img/page9.2.png'),
      img921: require('@/assets/img/page9.2.1.jpg'),
      img2: require('@/assets/img/page9.3.png'),
      img3s: [{
        img: require('@/assets/img/page9.4.1.png')
      }, {
        img: require('@/assets/img/page9.4.2.png')
      }, {
        img: require('@/assets/img/page9.4.3.png')
      }, {
        img: require('@/assets/img/page9.4.4.png')
      }, {
        img: require('@/assets/img/page9.4.5.png')
      }, {
        img: require('@/assets/img/page9.4.6.png')
      }, {
        img: require('@/assets/img/page9.4.7.png')
      }, {
        img: require('@/assets/img/page9.4.8.png')
      }, {
        img: require('@/assets/img/page9.4.9.png')
      }, {
        img: require('@/assets/img/page9.4.10.png')
      }, {
        img: require('@/assets/img/page9.4.11.png')
      }, {
        img: require('@/assets/img/page9.4.12.png')
      }],
      img5: require('@/assets/img/page9.5.1.png'),
      img6: require('@/assets/img/page9.5.2.png'),
      img7: require('@/assets/img/page9.5.3.png'),
      img8: require('@/assets/img/page9.5.4.png'),
      img9: require('@/assets/img/page9.5.5.png'),
      img10: require('@/assets/img/page9.5.6.png'),
      img11: require('@/assets/img/page9.5.7.png'),
      img12: require('@/assets/img/page9.5.8.png'),
      img13: require('@/assets/img/page9.5.9.png'),
      img14: require('@/assets/img/page9.5.10.png'),
      img15: require('@/assets/img/page9.6.1.png'),
      img16: require('@/assets/img/page9.6.2.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
img {
 width: 100%;
}
.pic1 {
  height: 3.94rem;
  background-image: url(../assets/img/page9.1.png);
  background-size: 3.75rem 3.94rem;
  margin-bottom: 0.2rem;
  .title {
    padding: 0.28rem 0 0.16rem 0;
    text-align: center;
    .titleA {
      font-family: PingFangSC-Medium;
      font-size: 0.24rem;
    }
    .titleB {
      font-size: 16px;
      font-family: PingFangSC-Medium;
      margin-bottom: 0.04rem;
    }
    .titleC {
      font-family: PingFangSC-Medium;
      color: #3974FB;
    }
  }
  .content {
    padding: 0 0.24rem 0 0.24rem;
    .contentA {
      font-family: PingFangSC-Medium;
      font-size: 0.14rem;
      margin-bottom: 0.08rem;
    }
    .contentB {
      font-family: PingFangSC-Regular;
      color: #333333;
    }
  }
}
.pic2 {
  margin: 0 0.24rem;
  .pic2_titleA {
    font-size: 14px;
    font-family: PingFangSC-Medium;
    margin-bottom: 0.08rem;
  }
  .pic2_titleB {
    font-family: PingFangSC-Regular;
    color: #333333;
    margin-bottom: 0.08rem;
  }
  .img1 {
    height: 2.28rem;
    margin-bottom: 0.4rem;
  }
  .img2 {
    height: 4.82rem;
    margin-bottom: 0.4rem;
  }
  .img3s {
    margin-bottom: 0.4rem;
    display: flex;
    flex-wrap: wrap;
    .img3 {
      width: 0.79rem;
      margin: 0 0.0275rem 0.005rem 0;
    }
  }
}
.pic3 {
  position: relative;
  .pic3_titleA {
    font-family: PingFangSC-Medium;
    font-size: 0.14rem;
    margin: 0 0 0.13rem 0.24rem;
  }
  .pic3_titleB {
    font-size: 0.099rem;
    font-family: SourceHanSansCN-Medium;
    margin-left: 0.33rem;
    color: #333333;
  }
  .pic3_border {
    position: absolute;
    left: 0.198rem;
    top: 0.41rem;
    border: 0.0055rem solid rgba(227, 230, 237, 1);
    width: 3.212rem;
    height: 20.68rem;
  }
  .pic3_form {
    width: 3.2175rem;
    margin: 0.14rem 0 0.11rem 0.3355rem;
    border-radius: 0.04rem;
    box-shadow: 0 0.01rem 0.05rem 0 rgba(206, 214, 232, 1);
    background-color: #FFFFFF;
    .pic3_form_title {
      padding: 0 0 0.08rem 0.195rem;
      display: flex;
      align-items: center;
      .round {
        width: 0.23rem;
        height: 0.23rem;
        border-radius: 50%;
        background-color: #FEE413;
      }
      .round-title {
        margin-left: -0.13rem;
        font-family: PingFangSC-Medium;
        font-size: 0.06rem;
      }
    }
    .pic3_form_imgs {
      padding: 0.04rem 0 0.175rem 0.235rem;
      display: flex;
      .img4 {
        width: 1.29rem;
        height: 2.53rem;
        margin-right: 0.17rem;
      }
      .img5 {
        width: 1.29rem;
        height: 2.53rem;
      }
    }
    .pic3_form_imgs2 {
      padding: 0 0.28rem 0.22rem 0.28rem;
      width: 2.6675rem;
    }
    .pic3_form_imgs3 {
      padding: 0 0.28rem 0.14rem 0.28rem;
      width: 2.6675rem;
    }
  }
}
.contact {
  margin-top: 0.4rem;
  text-align: center;
  .contact_title {
    font-size: 0.17rem;
    font-family: PingFangSC-Medium;
  }
  .contact_form {
    margin: 0.12rem 0.16rem 0.2rem 0.16rem;
    text-align: start;
    border-radius: 0.03rem;
    box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
    padding: 0.16rem 0.16rem 0.05rem 0.16rem;
    .contact_content {
      font-family: PingFangSC-Regular;
      margin-bottom: 0.12rem;
    }
  }
}
</style>
